@import 'variables';
@import '~@cigna/cigna_digital_dcws_cigna-frontend/src/scss/cigna-frontend';
@import 'components/header';
@import 'tools';

/* stylelint-disable selector-no-vendor-prefix */
/* stylelint-disable selector-max-compound-selectors */
_:-ms-fullscreen, :root .featured-topic {
  .focus-visible {
    outline: 0px;

    .background-block {
      outline: 3px solid #00a9e0;
    }
  }
}

_:-ms-fullscreen, :root .focus-visible {
  outline: 3px solid #00a9e0;
}
