/* position map icon */
.map-icon {
  display: block;
  position: absolute;
  right: 0.5rem;
  top: 1.25em;
  transform: translateY(-50%);
}

/* move zip code field success icon left of map icon */
#map-success .validation-icn {
  right: 2rem;
}

