.site-navigation-level-1 {
  .nav-item.active::after {
    background-color: #fefefe;
  }
}

.jpmc-header {
  .navbar-brand, .navbar-brand.jpmc-brand {
    img, svg {
      height: 38px;
      width: 150px;

      @include media-breakpoint-up(lg) {
        height: 66px;
        width: 192px;
      }
    }
  }

  .nav-logo-holder {
    align-items: center;
    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
  }

  .wellness-button {
    padding: 0.75rem 1.25rem;
  }

  .jpmc-logo {
    width: 350px;
  }

  .jp-home-link {
    background-color: transparent;
    background-image: none;
  }
}
